<template>
    <div class="slide-overlay" id="style">
        <div class="text-start">
            <div class="display-xl">
                Buy Eco-friendly fashion
            </div>
            <div class="heading-home subtitle">
                <div>
                    <div>from small, local shops</div>
                    <div class="mt-2">
                        across Europe
                    </div>
                </div>
            </div>
            <div class="button-row">
                <router-link to="/products"><button class="slide-button body-m" @click="selectGender('Male'); sendUserAction('HomeMen');">Shop Men</button></router-link>
                <router-link to="/products"><button class="slide-button body-m" @click="selectGender('Female'); sendUserAction('HomeWomen');">Shop Women</button></router-link>
            </div>
        </div>
    </div>
</template>

<script>


import axios from "axios";

export default {
    name: "HomeSlider",
    methods: {
        async sendUserAction(action){
          if(this.$route.path === '/'){
            let url = this.$backendURL + "/action/send?action="+action
            if(localStorage.getItem('token') != null){
              url += "&authenticationToken=" + localStorage.getItem('token')
            }
            await axios.post(url)
                .then(res => {
                  console.log(res);
                })
                .catch(err => {
                  console.error(err);
                })
          }
        },
        selectGender(gender) {
            this.$cookies.set('sm_selGen', gender);
        }
    }
}
</script>

<style scoped>
#style {
    background-image: url("../../assets/Home/HomeWoman.jpg");
}

#city {
    background-image: url("../../assets/Slider/City.jpg");
}

#planet {
    background-image: url("../../assets/Slider/Planet.jpg");
}

.slide-overlay {
    margin-top: -103.78px;
    height: calc(100vh - 170px);
    width: 100%;
    background-color: var(--darkGray);
    color: white;
    overflow: hidden;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.slide-button {
    height: 42px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 5px;
    padding-bottom: 7px;
    border: none;
    border-radius: 25px;
    text-decoration: none;
    background-color: white;
    color: black;
}

.slide-button:hover {
    background-color: #011e0a;
    color: white;
}

@media screen and (min-width: 1350px) {
    .subtitle {
        margin-top: 45px;
    }

    .slide-button {
        width: 160px;
    }

    .button-row {
        padding-right: var(--edge);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: calc(2 * var(--space));
        margin-top: -42px;
    }

    .slide-overlay {
        padding-top: 50vh;
        display: flex;
        flex-flow: column;
        justify-content: start;
        padding-left: var(--edge);
    }
}

@media screen and (max-width: 1349px) and (min-width: 744px) {
    .slide-button {
        width: 160px;
    }

    .subtitle {
        margin-top: 30px;
    }

    .button-row {
        margin-top: 30px;
        padding-right: var(--tablet-edge);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: calc(2 * var(--tablet-space));
    }

    .slide-overlay {
        height: calc(6 * var(--tablet-stripe) + 8 * var(--tablet-space));
        padding-top: calc(4 * var(--tablet-stripe) + 2 * var(--tablet-space));
        display: flex;
        flex-flow: column;
        justify-content: start;
        padding-left: var(--tablet-edge);
    }
}

@media screen and (max-width: 743px) {
    .slide-button {
        width: 160px;
    }

    .subtitle {
        margin-top: 30px;
    }

    .button-row {
        margin-top: 30px;
        padding-right: var(--mobile-edge);
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        gap: calc(2 * var(--mobile-space));
    }

    .slide-overlay {
        height: calc(8 * var(--mobile-stripe) + 7 * var(--mobile-space));
        padding-top: calc(6 * var(--mobile-stripe));
        display: flex;
        flex-flow: column;
        justify-content: start;
        padding-left: var(--tablet-edge);
    }
}
</style>