import {createRouter, createWebHistory} from 'vue-router'
import HomeView from '../views/Content/HomeView.vue'
import axios from "axios";


const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/our-mission',
        name: 'our-mission',
        //component: () => import(/* webpackChunkName: "about" */ '../views/Content/TextSample.vue')
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/OurMissionsView.vue')
    },
    {
        path: '/referral-program',
        name: 'referral-program',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/ReferralProgramView.vue')
    },
    {
        path: '/quality-standards',
        name: 'quality-standards',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/QualityStandardsView.vue')
    },
    {
        path: '/bitcoin',
        name: 'bitcoin',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/BitcoinView.vue')
    },
    /*{
      path: '/customer-faq',
      name: 'CustomerFAQ',
      component: () => import('../views/Content/CustomerFAQView.vue')
    },*/
    {
        path: '/founders-qa',
        name: 'Founders Q&A',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/InterviewView.vue'),
    },
    {
        path: '/founders-qa/:shopname',
        name: 'InterviewSingleView',
        component: () => import('../views/Content/InterviewSingleView.vue'),
    },
    {
        path: '/shopping',
        name: 'shopping',
        component: () => import(/* webpackChunkName: "about" */ '../views/Shop/ShopView.vue'),
        children: [
            {
                path: '/products',
                name: 'products',
                component: () => import('../views/Shop/ProductsView'),
            },
            {
                path: '/shop/products/:id',
                name: 'ProductDetails',
                component: () => import('../views/Shop/ProductDetails.vue')
            },
            {
                path: '/locations',
                name: 'locations',
                component: () => import('../views/Shop/LocationsView'),
            },
            {
                path: '/:id',
                name: 'ShopDetails',
                component: () => import(/* webpackChunkName: "about" */ '../views/Shop/ShopDetailsView.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "about" */ '../views/Authorization/LogInView.vue')
    },
    {
        path: '/register',
        name: 'RegisterView',
        component: () => import(/* webpackChunkName: "about" */ '../views/Authorization/RegisterView.vue')
    },
    {
        path: '/imprint',
        name: 'imprint',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/Legal/ImprintView.vue')
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/Legal/PrivacyView.vue')
    },
    {
        path: '/terms-and-conditions',
        name: 'terms-and-conditions',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/Legal/TermsConditionsView.vue')
    },
    {
        path: '/become-seller',
        name: 'become-seller',
        component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/BecomSeller.vue')
    },
    {
        path: '/team',
        name: 'team',
        component: () => import(/* webpackChunkName: "about" */ '../views/Content/TeamView.vue')
    },
    {
        path: '/confirmation/:token',
        name: 'UserConfirmationView',
        component: () => import(/* webpackChunkName: "about" */ '../views/Authorization/UserConfirmationView.vue')
    },
    {
        path: '/reset-password/:token',
        name: 'ResetPassword',
        component: () => import(/* webpackChunkName: "about" */ '../views/Authorization/ResetPassword.vue')
    },
    {
        path: '/referral-confirmation/:token',
        name: 'ReferralConfirmationView',
        component: () => import(/* webpackChunkName: "about" */ '../views/Authorization/ReferralConfirmationView.vue')
    },
    {
        path: '/cart',
        name: 'cart',
        component: () => import('../views/Shop/CartView.vue')
    },
    {
        path: '/wishlist',
        name: 'wishlist',
        component: () => import('../views/Shop/WishList.vue')
    },
    {
        path: '/account',
        name: 'account',
        component: () => import(/* webpackChunkName: "about" */ '../views/Account/AccountView.vue'),
        children: [
            {
                path: "/account",
                name: 'account_home',
                component: () => import(/* webpackChunkName: "about" */ '../views/Account/AccountHome.vue')
            },
            {
                path: "/account/settings",
                component: () => import(/* webpackChunkName: "about" */ '../views/Account/AccountSettings.vue')
            },
            {
                path: "/account/locations",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/MerchantMapView.vue'),
            },
            {
                path: "/account/profile",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/MerchantLandingPageView.vue')
            },
            {
                path: "/account/products",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/MerchantProductsView.vue')
            },
            {
                path: "/account/add-product",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/AddProductView.vue')
            },
            {

                path: "/account/edit-product/:id",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/AddProductView.vue')
            },
            {
                path: "/account/shop-details/:id",
                name: 'AdminShopDetails',
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminShopDetails.vue')
            },
            {
                path: "/account/shops",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminShopsView.vue')
            },
            {
                path: "/account/customer-journey",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminCustomerJourney.vue')
            },
            {
                path: "/account/admin-products",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminProductsView.vue')
            },
            {
                path: "/account/user",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminUserView.vue')
            },
            {
                path: "/account/tools",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminToolsView.vue')
            },
            {
                path: "/account/cities",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminCitiesView.vue')
            },
            {
                path: "/account/orders",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminOrders.vue')
            },
            {
                path: "/account/btcpay",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminBtcPay.vue')
            },
            {
                path: "/account/admin-terms",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminTerms.vue')
            },
            {
                path: "/account/seller-finances",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/MerchantFinances.vue')
            },
            {
                path: "/account/sales",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/MerchantOrdersView.vue')
            },
            {
                path: "/account/sales/:id",
                name: "MerchantOrderDetailsView",
                component: () => import(/* webpackChunkName: "about" */ '../views/Merchant/MerchantOrderDetailsView.vue')
            },
            {
                path: "/account/notifications",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminNotificationsView.vue')
            },
            {
                path: "/account/categories",
                component: () => import(/* webpackChunkName: "about" */ '../views/Admin/AdminCategoriesView.vue')
            },
            {
                path: "/account/personal-orders",
                component: () => import('../views/Account/AccountOrdersView.vue')
            },
            {
                path: "/account/referral-program",
                component: () => import('../views/Account/AccountReferralView.vue')
            },
            {
                path: "/account/addresses",
                component: () => import('../views/Account/AccountAddressesView.vue')
            },
            {
                path: "/account/orders/:id",
                name: "AccountOrderDetails",
                component: () => import('../views/Account/AccountOrderDetailsView.vue')
            }
        ]
    },
    {
        path: '/payment/success/:checkoutToken/:userId',
        name: 'PaymentSuccessView',
        component: () => import('../views/Shop/Payment/PaymentSuccessView.vue')
    },
    {
        path: '/payment/failure/:checkoutToken/:userId',
        name: 'PaymentFailureView',
        component: () => import('../views/Shop/Payment/PaymentFailureView.vue')
    },
    {
        path: '/checkout',
        name: 'Checkout',
        component: () => import( '../views/Shop/CheckoutView.vue')
    },
    {
        path: '/install-shopify',
        name: 'ShopifyInstall',
        component: () => import( '../views/Authorization/InstallShopify.vue')
    },
    {
        path: '/approve-shopify',
        name: 'ShopifyConnect',
        component: () => import( '../views/Authorization/ApproveShopify.vue')
    },
    /*{
      path: '/owners',
      name: 'Owners',
      component: () => import('../views/Shop/OwnersView.vue')
    },*/
    {
        path: '/:pathMatch(.*)*',
        component: HomeView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})


router.beforeEach((to, from, next) => {
    //set device_id

    if (localStorage.getItem('device_id') == null) {
        localStorage.setItem('device_id', Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15));
    }

    //const backendURL = "http://localhost:8080";
    const backendURL = "https://smallshops-server.ljmcoding.com:4003"
    document.cookie = "sm_to=" + to.fullPath
    document.cookie = "sm_from=" + from.fullPath

    if (from.fullPath === "/products") {
        localStorage.setItem('pScroll', window.scrollY);
    } else if (from.fullPath == "/locations") {
        localStorage.setItem('lScroll', window.scrollY);
    } else {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'instant'
        });
   }

    var token = localStorage.getItem('token');

    if (to.matched[0].path === '/account' || to.matched[0].path === '/checkout' || to.matched[0].path === '/wishlist') {
        if (token == null) {
            next({fullPath: "/login"})
        } else {
            axios({
                method: 'get',
                url: backendURL + "/user/" + token,
            })
                .then(res => {
                    const role = res.data.userRole;
                    if (to.fullPath === '/account/locations' && role !== "Merchant") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/profile' && role !== "Merchant") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/products' && (role !== "Merchant")) {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/add-product' && (role !== "Merchant")) {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/edit-product' && (role !== "Merchant")) {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/sales' && (role !== "Merchant")) {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/seller-finances' && (role !== "Merchant")) {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/checkout' && role == null) {
                        next({fullPath: "/login"})
                    } else if (to.fullPath === '/wishlist' && role == null) {
                        next({fullPath: "/login"})
                    } else if (to.fullPath === '/account/personal-orders' && (role === "Merchant")) {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/cities' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/btcpay' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/categories' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/notifications' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath.includes('/account/sales/') && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/user' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/admin-terms' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/shops' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/admin-products' && role !== "Admin") {
                        next({name: "account_home"})
                    } else if (to.fullPath === '/account/orders' && role !== "Admin") {
                        next({name: "account_home"})
                    } else {
                        next()
                    }
                })
                .catch(() => {
                    localStorage.removeItem("token")
                    next({fullPath: "/login"})
                })
        }
    } else {
        next();
    }
});
export default router