<template>
  <div class="ppg-container">
      <div class="ppg-top-row">
          <div class="heading-home">
              {{title}}
          </div>
          <router-link :to="redirectUrl" class="body-m text-dark" @click="selectCategory">
              {{redirectDesc}} >
          </router-link>
      </div>
      <div class="ppg-scroll-container">
          <div class="ppg-grid">
              <div v-for="(p, index) of productIds" :key="index">
                  <ProductCard :product-id="p" :image-limit="true"></ProductCard>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import ProductCard from "@/components/ProductCard.vue";

export default {
    name: "ProductPreviewGrid",
    components: {ProductCard},
    props: ["productIds", "title", "redirectUrl", "redirectDesc", "category"],
    data(){
        return{

        }
    },
    methods:{
        clearFilters(){
            this.$cookies.set('sm_city', 'All cities');
            this.$cookies.set('sm_category', [12, 13, 14, 15]);
            this.$cookies.set('sm_minPrice', 0);
            this.$cookies.remove('sm_maxPrice');
            this.$cookies.remove("sm_QsL")
            this.$cookies.remove("sm_selGen")
        },
        selectCategory(){
            this.clearFilters();
            if(this.$props.category != null){
                let categories = [];
                categories.push(this.$props.category);
                this.$cookies.set('sm_category', categories);
            }
        }
    },
    mounted() {

    }
}
</script>

<style scoped>



.ppg-container::-webkit-scrollbar{
    display: none;
}

.ppg-top-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ppg-grid{
    display: grid;
}

.ppg-scroll-container{
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
}

.ppg-scroll-container::-webkit-scrollbar{
    display: none;
}

@media screen and (min-width: 1350px){
  .ppg-grid {
      grid-template-columns: repeat(5, calc(2*var(--stripe) + var(--space)));
      grid-column-gap: var(--space);
  }

  .ppg-top-row{
      margin-top: 80px;
      margin-bottom: 30px;
  }
}

@media screen and (min-width: 744px) and (max-width: 1349px){

    .ppg-grid {
        grid-template-columns: repeat(5, calc(3*var(--tablet-stripe) + 2*var(--tablet-space )));
        grid-column-gap: var(--space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 743px){

    .ppg-grid {
        grid-template-columns: repeat(5, calc(3*var(--mobile-stripe) + 2*var(--mobile-space)));
        grid-column-gap: var(--mobile-space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
</style>