<template>
  <HomeSlider></HomeSlider>
  <div class="product-preview">
      <ProductPreviewGrid title="Popular products"
                          redirect-url="products"
                          redirect-desc="shop now"
                          :product-ids="[802, 862, 922, 925, 1002]">
      </ProductPreviewGrid>
  </div>
  <div class="product-preview">
      <ShopPreviewGrid redirect-url="locations"
                          redirect-desc="get directions"
                          :shop-ids="[27, 17, 59, 9, 83]">
      </ShopPreviewGrid>
  </div>
  <div class="home-subtitle heading-home">
      <p class="text-start">Only high quality products</p>
  </div>
  <div id="quality-content">
      <QualityStandardsTable></QualityStandardsTable>
  </div>
  <div class="product-preview">
      <CityPreviewSlider
      title="All over Europe"></CityPreviewSlider>
  </div>
  <div class="product-preview">
      <CategoryPreview title="Filter by category"></CategoryPreview>
  </div>
  <div class="product-preview">
      <ProductPreviewGrid title="Clothing"
                          redirect-url="products"
                          redirect-desc="more clothing"
                          category="12"
                          :product-ids="[802, 862, 922, 925, 1002]">
      </ProductPreviewGrid>
  </div>
  <div class="product-preview">
      <ProductPreviewGrid title="Accessories"
                          redirect-url="products"
                          redirect-desc="more accessories"
                          category="13"
                          :product-ids="[1012, 335, 329, 109, 148]">
      </ProductPreviewGrid>
  </div>
  <div class="product-preview">
      <ProductPreviewGrid title="Intimates"
                          redirect-url="products"
                          redirect-desc="more intimates"
                          category="14"
                          :product-ids="[387, 385, 365, 343, 383]">
      </ProductPreviewGrid>
  </div>
  <div class="product-preview">
      <ProductPreviewGrid title="Bags"
                          redirect-url="products"
                          redirect-desc="more bags"
                          category="15"
                          :product-ids="[28, 22, 827, 209, 29]">
      </ProductPreviewGrid>
  </div>
</template>

<script>
import QualityStandardsTable from "@/components/Content/QualityStandardsTable";
import ProductPreviewGrid from "@/components/Shop/ProductPreviewGrid.vue";
import CityPreviewSlider from "@/components/Content/CityPreviewSlider.vue";
import CategoryPreview from "@/components/Content/CategoryPreview.vue";
import ShopPreviewGrid from "@/components/Shop/ShopPreviewGrid.vue";
import HomeSlider from "@/components/Content/HomeSlider.vue";
export default {
  name: 'HomeView',
  components: {
      HomeSlider,
      ShopPreviewGrid,
      CategoryPreview,
      CityPreviewSlider, ProductPreviewGrid, QualityStandardsTable},
  data(){
    return{
      mapPoints: [],
      limit: 0
    }
  },
  methods:{
    changeLimit(){
        if(window.innerWidth>=744 && window.innerWidth<1350){
            this.limit = 3;
        }else{
            this.limit = 5;
        }
    }
  },
  mounted() {
      if(window.innerWidth>=744 && window.innerWidth<1350){
          this.limit = 3;
      }else{
          this.limit = 5;
      }
    window.addEventListener('resize', this.changeLimit);
  }
}
</script>
<style>


@media screen and (min-width: 1350px){
    .product-preview{
        margin-left: var(--edge);
        margin-right: var(--edge);
    }


  .home-subtitle{
    margin-left: var(--edge);
    margin-top: 80px;
    margin-bottom: 30px;
  }


  .map-block{
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
    background-color: var(--lightGray);
    height: calc(3*var(--stripe) + 2*var(--space)) !important;
    grid-column-gap: var(--space);
    margin-left: calc(2*var(--stripe) + 2*var(--space) + 1*var(--edge));
    margin-top: calc(3*var(--column));
  }

  #map-block-1{
      padding-right: var(--space);
  }

  #map-block-2{
    height: calc(3*var(--stripe) + 2*var(--space)) !important;
  }

  #quality-content{
    height: auto !important;
    margin: auto;
    width: calc(6*var(--stripe) + 5*var(--space));
    margin-top: calc(3*var(--column));
    padding-top: 25px;
    padding-bottom: 25px;
  }

}

@media screen and (max-width: 1349px) and (min-width: 744px) {

    .product-preview{
        margin-left: var(--tablet-edge);
        margin-right: var(--tablet-edge);
    }

    .home-subtitle{
        margin-left: var(--tablet-edge);
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .map-block{
        display: grid;
        grid-template-columns: 50% 50%;
        grid-column-gap: var(--tablet-space);
        align-items: center;
        margin-top: calc(3*var(--column));
        margin-left: calc(var(--tablet-stripe) + var(--tablet-space) + var(--tablet-edge));
        background-color: var(--lightGray);
    }

    #map-block-1{
        margin-right: var(--tablet-space);
    }

    #map-block-2{
        height: calc(3*var(--tablet-stripe) + 2*var(--tablet-space)) !important;
    }


    #quality-content{
        height: auto !important;
        display: flex;
        justify-content: center;
        width: 100%;
        margin-top: calc(3*var(--column));
        padding-top: 25px;
        padding-bottom: 25px;
    }

}

@media screen and (max-width: 743px){

    .product-preview{
        margin-left: var(--mobile-edge);
        margin-right: var(--mobile-edge);
    }

  .home-subtitle{
    margin-left: var(--mobile-edge);
    margin-top: 50px;
    margin-bottom: 30px;
  }

  .home-subtitle p{
    margin: 0 !important;
  }

  .map-block{
    display: grid;
    grid-template-columns: 100%;
    align-items: start;
    margin-top: calc(1*var(--mobile-edge)) !important;
    margin-left: 0 !important;
    margin-right: calc(3*var(--mobile-edge)) !important;
    background-color: var(--lightGray);
  }

  #map-block-1{
    order: 2;
    margin-left: var(--mobile-edge);
    margin-right: var(--mobile-edge);
    margin-top: calc(2*var(--mobile-edge));
    margin-bottom: calc(1*var(--mobile-edge));
    height: calc(3*var(--mobile-stripe) + 2*var(--mobile-space) + 1*var(--mobile-edge));
  }

  #map-block-1-desc{
    margin-bottom: calc(3*var(--column));
  }

  #map-block-2{
    order: 1;
    width: calc(100vw - 3*var(--mobile-edge));
    height: calc(100vw - 3*var(--mobile-edge));
  }

  #quality-content {
    height: auto !important;
    margin-left: var(--mobile-edge);
    margin-top: calc(3 * var(--column));
    padding-top: 25px;
    padding-bottom: 25px;
  }
}

</style>
