<template>
    <div class="ppg-container">
        <div class="ppg-top-row">
            <div v-if="!mobileView" class="heading-home text-start">
                Discover physical shops near you
            </div>
            <div v-else class="heading-home text-start">
                Discover physical shops
            </div>
            <router-link :to="redirectUrl" class="body-m text-dark" @click="clearFilters">
                {{redirectDesc}} >
            </router-link>
        </div>
        <div class="ppg-scroll-container">
            <div class="ppg-grid">
                <div v-for="(p, index) of shopIds" :key="index">
                    <ShopCard :shop-id="p" :image-limit="true" :preview-grid="true"></ShopCard>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ShopCard from "@/components/ShopCard.vue";

export default {
    name: "ShopPreviewGrid",
    components: {ShopCard},
    props: ["shopIds", "title", "redirectUrl", "redirectDesc"],
    data(){
        return {
            mobileView: false
        }
    },
    methods:{
        clearFilters(){
            this.$cookies.set('sm_city', 'All cities');
            this.$cookies.set('sm_category', [12, 13, 14, 15]);
            this.$cookies.set('sm_minPrice', 0);
            this.$cookies.remove('sm_maxPrice');
            this.$cookies.remove("sm_QsL")
            this.$cookies.remove("sm_selGen")
        },
        handleResize(){
            if(window.innerWidth < 744){
                this.mobileView = true;
            }else{
                this.mobileView = false;
            }
        }
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }
}
</script>

<style scoped>

.ppg-scroll-container::-webkit-scrollbar{
    display: none;
}

.ppg-top-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ppg-grid{
    display: grid;
}

.ppg-scroll-container{
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
}

.ppg-scroll-container::-webkit-scrollbar{
    display: none;
}

@media screen and (min-width: 1350px){
    .ppg-grid {
        grid-template-columns: repeat(5, calc(2*var(--stripe) + var(--space)));
        grid-column-gap: var(--space);
    }

    .ppg-top-row{
        margin-top: 80px;
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 744px) and (max-width: 1349px){

    .ppg-grid {
        grid-template-columns: repeat(5, calc(3*var(--tablet-stripe) + 2*var(--tablet-space )));
        grid-column-gap: var(--space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 743px){

    .ppg-grid {
        grid-template-columns: repeat(5, calc(3*var(--mobile-stripe) + 2*var(--mobile-space)));
        grid-column-gap: var(--mobile-space);
        height: calc(4*var(--mobile-stripe) + 2*var(--mobile-space));
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }
}
</style>