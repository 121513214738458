<template>
    <div class="ul-container">
        <div class="qs-grid">
          <img src="../../assets/icons/Organic.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Organic Materials</div>
            <p class="text-start item-desc body-m">
              Items are crafted using certified organic
              materials, that are grown without the use of
              harmful pesticides or chemicals.
            </p>
          </div>
        </div>
        <div class="qs-grid">
          <img src="../../assets/icons/Handmade.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Handmade</div>
            <p class="text-start item-desc body-m">
              Items are created through the hands-on
              process of skilled artisans, imbuing each
              piece with a unique character and charm.
            </p>
          </div>
        </div>
        <div class="qs-grid">
          <img src="../../assets/icons/Local.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Local Production</div>
            <p class="text-start item-desc body-m">
              Manufacturing takes place within the local
              community, using often traditional production
              methods.
            </p>
          </div>
        </div>
        <div class="qs-grid" v-if="!mobileView || expand">
          <img src="../../assets/icons/Recycled.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Up-/ Recycled</div>
            <p class="text-start item-desc body-m">
              Items are made from materials that have been
              repurposed or recycled, giving them a second
              life.
            </p>
          </div>
        </div>
        <div class="qs-grid" v-if="!mobileView || expand">
          <img src="../../assets/icons/Fair_Trade.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Fair Trade</div>
            <p class="text-start item-desc body-m">
              Shops ensure, that workers are treated with
              dignity and provided with fair wages and
              working conditions.
            </p>
          </div>
        </div>
        <div class="qs-grid" v-if="!mobileView || expand">
          <img src="../../assets/icons/Transparent_Supply_Chain.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Transparent Supply Chain</div>
            <p class="text-start item-desc body-m ">
              The supply chain is transparent at all stages, from
              sourcing to manufacturing to distribution.
            </p>
          </div>
        </div>
        <div class="qs-grid" v-if="!mobileView || expand">
          <img src="../../assets/icons/Co2.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Reduced CO2 Emissions</div>
            <p class="text-start item-desc body-m">
              Efforts are made to reduce the carbon
              footprint of the production process, including
              the use of renewable energy sources.
            </p>
          </div>
        </div>
        <div class="qs-grid" v-if="!mobileView || expand">
          <img src="../../assets/icons/Plastic.svg" class="icon">
          <span></span>
          <div>
            <div class="text-success text-start body-l-bold qs-item-title">Reduced Plastic</div>
            <p class="text-start item-desc body-m">
              During the production process and packaging,
              shops strive to minimize the use of harmful
              plastic materials.
            </p>
          </div>
        </div>
        <div v-if="mobileView && !expand" @click="expand=!expand">
            <img src="../../assets/icons/Arrow.svg" class="icon-arrow">
        </div>
        <div v-if="mobileView && expand" @click="expand=!expand">
            <img src="../../assets/icons/Arrow.svg" class="icon-arrow" id="arrow-up">
        </div>
    </div>
</template>

<script>
export default {
  name: "QualityStandardsTable",
  data(){
      return{
          mobileView: false,
          expand: false
      }
  },
  methods:{
      handleResize(){
          if(window.innerWidth < 744) {
              this.mobileView = true;
          } else {
              this.mobileView = false;
          }
      }
  },
    mounted() {
        window.addEventListener('resize', this.handleResize);
        this.handleResize();
    }
}
</script>

<style scoped>

.qs-item-title{
    margin-bottom: 2px;
}

.icon-arrow{
    width: 20px;
    height: 20px;
    margin-top: 10px;
}

#arrow-up{
    transform: rotate(180deg);
}

@media screen and (min-width: 1350px){
    .icon{
        width: 80px;
        height: 80px;
    }

  .ul-container{
    width: calc(2* (400px + var(--space)) + 80px);
    display: grid;
    grid-template-columns: calc(400px + var(--space)) calc(400px + var(--space));;
    grid-column-gap: 80px;
    grid-row-gap: var(--space);
    grid-template-rows: repeat(4, 95px);
  }

  .qs-grid{
    width: calc(400px + var(--space));
    margin: 0;
    display: grid;
    grid-template-columns: 80px var(--space) 320px;
  }

  .item-desc{
      margin-top: 5px;
      margin-bottom: 0 !important;
  }

}

@media screen and (max-width: 1349px) and (min-width: 744px){
    .icon{
        width: 70px;
        height: 70px;
    }

    .ul-container{
        width: calc(2 * (300px + 1.5*var(--tablet-space)) + 2*var(--tablet-space));
        display: grid;
        grid-template-columns: calc(300px + 1.5*var(--tablet-space)) calc(300px + 1.5*var(--tablet-space));
        grid-column-gap: calc(2*var(--tablet-space));
        grid-row-gap: calc(2*var(--tablet-space));
        justify-content: start;
    }

    .qs-grid{
        width: calc(300px + 1.5*var(--tablet-space));
        display: grid;
        grid-template-columns: 70px var(--tablet-space) calc(230px + 0.5*var(--tablet-space));
    }

    .item-desc{
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 0 !important;
    }

}


@media screen and (max-width: 743px){
    .icon{
        width: 65px;
        height: 65px;
    }

  .item-desc{
    font-size: 0.9rem;
    font-weight: 400;
  }

  .ul-container{

    width: calc(100vw - 2*var(--mobile-edge));
    display: grid;
    grid-template-columns: 100%;
    grid-row-gap: var(--space);
  }

  .qs-grid{
      width: calc(100vw - 2*var(--mobile-edge));
      margin: 0;
      display: grid;
      grid-template-columns: 65px var(--mobile-space) calc(100vw - 2*var(--mobile-edge) - 65px - var(--mobile-space));
  }

}
</style>