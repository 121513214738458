<template>
    <div class="ppg-container">
        <div class="ppg-top-row">
            <div class="heading-home">
                {{title}}
            </div>
        </div>
        <div class="ppg-scroll-container">
            <div class="ppg-grid">
                <router-link to="/products" class="nav-link" @click="selectCategory(12)">
                    <img src="../../assets/Categories/Category_Clothing.jpg" alt="Clothing" class="city-img">
                </router-link>
                <router-link to="/products" class="nav-link" @click="selectCategory(13)">
                    <img src="../../assets/Categories/Category_Accessories.jpg" alt="Accessoires" class="city-img">
                </router-link>
                <router-link to="/products" class="nav-link" @click="selectCategory(14)">
                    <img src="../../assets/Categories/Category_Initimites.jpg" alt="Intimates" class="city-img">
                </router-link>
                <router-link to="/products" class="nav-link" @click="selectCategory(15)">
                    <img src="../../assets/Categories/Category_Bags.jpg" alt="Bags" class="city-img">
                </router-link>
                <router-link to="/products" id="go-shopping" @click="clearFilters">
                    <svg xmlns="http://www.w3.org/2000/svg" width="71.504" height="71.504" viewBox="0 0 71.504 71.504">
                        <g id="Cart" transform="translate(-5 -5)">
                            <path id="Pfad_1513" data-name="Pfad 1513" d="M13.6,18.742a12.742,12.742,0,1,1,25.485,0" transform="translate(14.41)" fill="none" stroke="#000" stroke-miterlimit="10" stroke-width="2"/>
                            <path id="Pfad_1514" data-name="Pfad 1514" d="M67.974,10.4H13.53L6,67.162H75.5Z" transform="translate(0 8.342)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2"/>
                        </g>
                        <path id="Icon_ionic-ios-arrow-round-back" data-name="Icon ionic-ios-arrow-round-back" d="M16.939,11.571a1.135,1.135,0,0,1,.009,1.6l-5.271,5.288H31.762a1.129,1.129,0,0,1,0,2.258H11.677L16.957,26a1.143,1.143,0,0,1-.009,1.6,1.124,1.124,0,0,1-1.589-.009L8.2,20.385h0a1.268,1.268,0,0,1-.234-.356,1.077,1.077,0,0,1-.087-.434A1.132,1.132,0,0,1,8.2,18.8L15.359,11.6A1.106,1.106,0,0,1,16.939,11.571Z" transform="translate(55.635 61.678) rotate(180)"/>
                    </svg>
                    <div class="heading-xl">
                        Go shopping
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "CategoryPreview",
    props: ["title"],
    data(){
        return{
        }
    },
    methods: {
        clearFilters(){
            this.$cookies.set('sm_city', 'All cities');
            this.$cookies.set('sm_category', [12, 13, 14, 15]);
            this.$cookies.set('sm_minPrice', 0);
            this.$cookies.remove('sm_maxPrice');
            this.$cookies.remove("sm_QsL")
            this.$cookies.remove("sm_selGen")
        },
        selectCategory(category){
            this.clearFilters();
            let categories = [];
            categories.push(category);
            this.$cookies.set('sm_category', categories);
        }
    },
    mounted() {

    }
}
</script>

<style scoped>

#go-shopping{
    border: 1px solid black;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    cursor: pointer;
    color: black;
    text-decoration: none;
}

.ppg-top-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.ppg-grid{
    display: grid;
}

.ppg-scroll-container{
    width: 100%;
    overflow: scroll;
    -ms-overflow-style: none;
}

.ppg-scroll-container::-webkit-scrollbar{
    display: none;
}

@media screen and (min-width: 1350px){
    .ppg-grid {
        grid-template-columns: repeat(5, calc(2*var(--stripe) + var(--space)));
        grid-column-gap: var(--space);
    }

    .ppg-top-row{
        margin-top: 80px;
        margin-bottom: 30px;
    }

    .city-img{
        height: calc(2*var(--stripe) + var(--space));
    }
}

@media screen and (min-width: 744px) and (max-width: 1349px){

    .ppg-grid {
        grid-template-columns: repeat(5, calc(3*var(--tablet-stripe) + 2*var(--tablet-space )));
        grid-column-gap: var(--space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .city-img{
        height: calc(3*var(--tablet-stripe) + 2*var(--tablet-space ));
    }
}

@media screen and (max-width: 743px){

    .ppg-grid {
        grid-template-columns: repeat(5, calc(3*var(--mobile-stripe) + 2*var(--mobile-space)));
        grid-column-gap: var(--mobile-space);
    }

    .ppg-top-row{
        margin-top: 50px;
        margin-bottom: 30px;
    }

    .city-img{
        height: calc(3*var(--mobile-stripe) + 2*var(--mobile-space));
    }
}
</style>