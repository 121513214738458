<template>
  <div class="cart-popup-container" data-aos="fade-down" data-aos-duration="1000">
      <div class="body-l-bold">Cart Summary</div>
      <div class="cart-popup-top-row">
          <div class="body-m-light">{{itemLength}} Items:</div>
          <div class="body-m-bold">{{totalCost}} €</div>
      </div>
      <div class="cart-popup-item-container" v-if="this.cartItems == null">
          <div class="spinner-border"></div>
      </div>
      <div class="cart-popup-item-container" v-if="this.cartItems != null">
            <div class="cart-popup-item" v-for="(cartitem, index) of cartItems" :key="index">
                <router-link class=" text-dark text-decoration-none" :to="{name: 'ProductDetails', params: {id: cartitem.cartItemDtoProductVariantDto.productVariantDtoProductId}}">
                    <img :src="cartitem.cartItemDtoImageUrl" :alt="cartitem.cartItemDtoProductVariantDto.productVariantDtoTitle" class="cart-popup-item-thumbnail">
                </router-link>
                <div class="cart-popup-item-details">
                    <div class="cart-popup-row body-m-bold">
                        <div>{{cartitem.cartItemDtoProductVariantDto.productVariantDtoPrice.toFixed(2)}} €</div>
                        <img src="../../assets/icons/Close_Register.svg" alt="close icon" class="del-cart-item" @click="this.remove(cartitem.cartItemDtoId)">
                    </div>
                    <div class="body-m cart-product-title">
                        {{cartitem.cartItemDtoProductVariantDto.productVariantDtoTitle.substring(0, 24)}}...
                    </div>
                    <div class="label-m-light cart-label">
                        Size: {{cartitem.cartItemDtoProductVariantDto.productVariantDtoSize}}
                    </div>
                    <div class="label-m-light cart-label">
                        Color: {{cartitem.cartItemDtoProductVariantDto.productVariantDtoColor}}
                    </div>
                    <div class="label-m-light">
                        Quantity: {{cartitem.cartItemDtoQuantity}}
                    </div>
                </div>
            </div>
      </div>
      <div class="cart-bottom-row">
          <router-link to="/cart" class="button-transparent cart-popup-button">See cart</router-link>
          <img src="../../assets/icons/Close_Register.svg" alt="close icon" class="cart-popup-icon" @click="this.$emit('close');">
      </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
    name: "CartPopUp",
    emits: ["close"],
    data(){
        return{
            customerToken: null,
            cartItems: null,
            totalCost: 0,
            priceSpinners: false,
            maxQuantityNotification: false,
            itemLength: 0,
        }
    },
    methods:{
        async getCart(){
            let url = this.$backendURL+"/cart/get?device_id="+localStorage.getItem('device_id');
            if (this.customerToken != null) {
                url += "&authenticationToken=" + this.customerToken;
            }
            await axios({
                method: 'get',
                url: url
            })
                .then(res =>{
                    this.cartItems = res.data.cartItemDtoList;
                    this.totalCost = res.data.totalCost.toFixed(2);
                    this.getItemLength();
                })
                .catch(err => {
                    console.error(err);
                })
        },
        async remove(id){
            let url = this.$backendURL+"/cart/delete?device_id="+localStorage.getItem('device_id')+"&cartDtoId="+id;
            if (this.customerToken != null) {
                url += "&authenticationToken=" + this.customerToken;
            }
            await axios({
                method: 'delete',
                url: url,
            })
                .then(res => {
                    let cartCount = document.getElementById('nav_cart_count')
                    if(cartCount!=null){
                        cartCount.innerText = res.data;
                    }
                    this.$swal({
                        text: "Product removed from Cart. Please continue",
                        icon: "success"
                    });
                    let index = this.cartItems.findIndex(o => o['cartItemDtoId'] == id)
                    this.cartItems.splice(index, 1);
                })
                .catch(err => {
                    console.error(err);
                })
            this.updateTotalCost();
            this.getItemLength();
        },
        getItemLength(){
            let count = 0;
            for(const element of this.cartItems) {
                count += element.cartItemDtoQuantity
            }
            this.itemLength = count;
        },
        updateTotalCost(){
            this.totalCost = 0.0
            this.cartItems.forEach(o => {
                this.totalCost += o['cartItemDtoQuantity']*o['cartItemDtoProductVariantDto'].productVariantDtoPrice
            })
        },
    },
    mounted(){
        this.customerToken = localStorage.getItem('token');
        this.getCart();
    }
}
</script>

<style scoped>
.cart-popup-icon{
    height: 35px;
    width: 35px;
}

.cart-label{
    margin-bottom: 5px;
}

.cart-product-title{
    margin-bottom: 6px;
}

.cart-popup-button{
    height: 35px;
    text-align: center;
    width: 121px;
}

.cart-bottom-row{
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
}

.cart-popup-item-container{
    margin-bottom: 15px;
    display: flex;
    flex-flow: column;
    gap: 21px;
}

.cart-popup-item-thumbnail{
    width: 121px;
    height: 121px;
}

.cart-popup-container{
    background-color: var(--lightGray);
    width: calc(2*121px + 3*21px);
    position: fixed;
    z-index: 8;
    padding: 21px;
    right: calc(var(--edge) - 21px);
    top: 104.78px;
    text-align: left;
    -webkit-box-shadow: 0 4px 15px -3px rgba(0,0,0,0.4);
    box-shadow: 0 4px 15px -3px rgba(0,0,0,0.4);
}

.cart-popup-top-row{
    display: flex;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: calc(1.5*21px);
}

.cart-popup-row{
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
}

.cart-popup-item{
    display: grid;
    grid-template-columns: repeat(2, 121px);
    grid-column-gap: 21px;
    grid-row-gap: 21px;
}

.del-cart-item{
    margin-top: -2px;
    height: 18px;
    width: 18px;
}

@media screen and (min-width: 743px) and (max-width: 1349px){
    .cart-popup-container{
        top: 80px;
        right: calc(var(--tablet-edge) - 42px);
    }
}

@media screen and (max-width: 743px) {
    .cart-popup-container{
        top: 74px;
        right: calc(2*var(--mobile-edge));
        z-index: 11;
    }
}
</style>