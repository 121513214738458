<template>
    <ShopNavigation></ShopNavigation>
    <div class="seperator-div" v-if="!isTransparent"></div>
    <router-view></router-view>
    <MainFooter></MainFooter>
</template>

<script>

/**
 * App.vue
 *
 * Purpose:
 * This is the main component of the vue js app
 *
 * Functions:
 *
 * Components:
 *
 */

  import ShopNavigation from "@/components/ShopNavigation";
  import MainFooter from "@/components/MainFooter";
  import AOS from "aos";
  export default{
    components: {ShopNavigation, MainFooter},
    data(){
      return {
        baseURL : "https://smallshops-server.ljmcoding.com:4003", //production server - baseURL defines the Url to the small-shops backend. the variable will be passed as props to every component
        //baseURL: "http://192.168.0.185:8080", //development server - baseURL defines the Url to the small-shops backend. the variable will be passed as props to every component
        isTransparent: true,
      }
    },
      methods:{
          handleScroll(){
              if(window.scrollY < 300 && this.$route.fullPath == "/"){
                  this.isTransparent = true;
              }else{
                  this.isTransparent = false;
              }
          }
      },
    mounted() {
      AOS.init({
        once: true
      }); //initialize animation library
      //window.scrollTo(0,0) //scrolling to the top of the page after every route
        window.addEventListener('scroll', this.handleScroll);
        this.handleScroll();
    },
      watch:{
          '$route'(){
              this.handleScroll();
          }
      }
  }
</script>

<style>
/*
@font-face {
  font-family: Selawik;
  src: url('../src/assets/fonts/selawk.ttf');
  url("../src/assets/fonts/selawkb.ttf") format("truetype"),
  url("../src/assets/fonts/selawkl.ttf") format("truetype"),
  url("../src/assets/fonts/selawksb.ttf") format("truetype"),
  url("../src/assets/fonts/selawksl.ttf") format("truetype");
}*/

@font-face {
  font-family: "Selawik";
  src: url("../src/assets/fonts/selawkl.ttf") format("truetype");
  font-weight: 200;
}

@font-face {
  font-family: "Selawik";
  src: url("../src/assets/fonts/selawksl.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Selawik";
  src: url("../src/assets/fonts/selawk.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Selawik";
  src: url("../src/assets/fonts/selawksb.ttf") format("truetype");
  font-weight: 500;
}

@font-face {
  font-family: "Selawik";
  src: url("../src/assets/fonts/selawkb.ttf") format("truetype");
  font-weight: 600;
}

#app {
  font-family: Selawik;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 1350px){
    .seperator-div{
        height: 165.97px;
    }
}

@media screen and (max-width: 1349px) and (min-width: 734px) {
    .seperator-div{
        height: 165.97px;
    }

    body{
        height: 100%;
        overflow: hidden;
        overscroll-behavior: none;
    }
}

@media screen and (max-width: 743px){
    .seperator-div{
        height: 165.97px;
    }

    body{
        height: 100%;
        overflow: hidden;
        overscroll-behavior: none;
    }
}


</style>
